<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
import '@/assets/js/iconfont.js';
export default {
  name: 'App',

}
</script>

<style>
@import '@/assets/css/iconfont.css';


</style>
